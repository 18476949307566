import React from 'react';
import { Layout } from '../components/shared';
import Banner from '../components/homepage/Banner';

const NotFoundPage = () => (
  <Layout>
    <Banner
      text="Sorry, the page you are looking for does not exist"
      subText="Use the navigation above to find what you are looking for."
    />
  </Layout>
);

export default NotFoundPage;
